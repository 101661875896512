.blue {
    color: var(--blue);
}

#deals-top {
    margin-top: 75px;
}

.infinite-scroll-text {
    width: 100vw;
    display: flex;
    flex-direction: row;
    gap: 50px;
    background-color: var(--header-bg);
    height: 75px;
    margin-bottom: 130px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 100px;
}

.scroll-text {
    display: inline-block;
    animation: scroll-text 8s linear infinite;
}

.text-scrolling {
    display: inline-block;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    font-style: italic;
    letter-spacing: 0.5px;
    width: auto;
    padding-right: 50px;
}

@keyframes scroll-text {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-25%);
    }
}

.deal-grid.content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 135px;
}

.deal {
    width: 385px;
    height: 385px;
    position: relative;
    border-radius: 8px 8px 45px 45px;
}

.deal-bg-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 45px 45px;
    object-fit:cover;
}

.deal-content {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(0,0,0,0.72);
    border-radius: 8px 8px 45px 45px;
    color: #fff;
}

.deal-game-name {
    font-size: 32px;
    letter-spacing: 1px;
    font-weight: 500;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.5);
}

.deal-bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.steam-link {
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    padding: 6px 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    transition: 0.2s;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.25);

    &:hover {
        background-color: var(--blue);
        border: 1px solid var(--blue);
        transform: translateY(-2px);
    }
}

.steam-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
}

.steam-text {
    font-size: 18px;
}

.sale {
    font-size: 16px;
    padding: 4px 12px;
    background-color: var(--blue);
    border-radius: 40px;
}

@media screen and (max-width: 1340px) {
    .deal {
        width: 352px;
        height: max-content;
    }

    .deal-content {
        height: max-content;
        min-height: calc(385px - 50px);
    }
}

@media screen and (max-width: 1220px) {
    .deal {
        width: 320px;
    }

    .deal-bottom {
        flex-direction: column-reverse;
        justify-content: start;
        align-items: start;
        gap: 12px;
    }
}

@media screen and (max-width: 1024px) {
    .deal {
        width: 300px;
    }

    .steam-link {
        width: calc(100% - 50px);
    }
}

@media screen and (max-width: 960px) {

    .deal-grid.content {
        justify-content: start;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
    }

    .deal {
        flex: 1 1;
        width: auto;
        min-width: 295px;
    }

    .steam-link {
        width: max-content;
    }
}

@media screen and (max-width: 400px) {
    .deal {
        width: 100%;
        min-width: none !important; 
    }
}

#steam-release {
    text-decoration: none;
    color: var(--main-text);
    width: 100%;
    position: relative;
    background-color: var(--header-bg);
    height: 130px;
    margin-top: 50px;
    margin-bottom: 50px;

    .contentti {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.75);
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
    }
}

.steam-release-bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
}

.steam-release-text {
    text-transform: uppercase;
    font-size: 36px;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.4);
    color: #fff;
}

#future-updates {
    background-color: var(--header-bg);
    margin-bottom: 50px;
    padding: 40px 0 40px;
    
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }
}

.future-deal-head {
    font-size: 32px;
    letter-spacing: 1px;
    font-weight: 400;
}

.newsletter-head {
    font-size: 24px;
    letter-spacing: 0.75px;
    letter-spacing: 300;
    color: var(--third-text);
}

.newsletter-form-container {
    background-color: var(--body-main-bg);
    padding: 13px;
    border-radius: 8px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max-content;
}

.newsletter-form-input {
    border: none;
    outline: none;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-family: 'Poppins';
    font-size: 16px;
    color: var(--main-text);
    letter-spacing: 0.55px;
    font-weight: 300;

    &::placeholder {
        font-family: 'Poppins';
        color: var(--third-text);
        font-weight: 300;
        letter-spacing: 0.5px;
        font-size: 16px;
        margin-top: 2px;
    }
}

.sign-up-btn {
    background-color: var(--header-bg);
    color: var(--main-text);
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }
}

.social-media-head {
    font-size: 22px;
    letter-spacing: 0.75px;
    letter-spacing: 300;
    font-weight: 300;
    color: var(--main-text);
}

.social-media-grid {
    margin-top: -15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.social-media {
    text-decoration: none;
    color: var(--main-text);
    transition: 0.2s ease;

    &:hover {
        opacity: 0.75;
        transform: translateY(-2px);
    }
}

.social-media-icon {
    width: 26px;
    height: 26px;
    font-size: 26px;
}

@media screen and (max-width: 600px) {
    .steam-release-text {
        text-align: center;
        font-size: 26px;
    }

    .future-deal-head {
        font-size: 28px;
        text-align: center;
    }

    .social-media-head {
        font-size: 24px;
        text-align: center;
    }
}