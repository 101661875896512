body.false {
    --body-main-bg: #181818;
    --header-bg: #131313;
    --border-color: #232323;

    --main-text: #FFF;
    --secondary-text: rgb(220,220,220);
    --third-text: rgb(230,230,230);

    --blue: #648EC2;
}

body.true {
    --body-main-bg: #fff;
    --header-bg: #e6e6e6;
    --border-color: #000000;
    --main-text: #000;
    --secondary-text: #191919;
    --third-text: #282828;
    --blue: #0073ff;
}
body.true textarea {
    background-color: #fff; /* Set the background color to white */
    color: var(--main-text); /* Set the text color to the main text color */
    border-style: solid;
    border-width: 1px;
    border-color: var(--border-color)
}
body.true input {
    background-color: #fff; /* Set the background color to white */
    color: var(--main-text); /* Set the text color to the main text color */
    border-style: solid;
    border-width: 1px;
    border-color: var(--border-color)
}
body.true select {
    background-color: #fff; /* Set the background color to white */
    color: var(--main-text); /* Set the text color to the main text color */
    border-style: solid;
    border-width: 1px;
    border-color: var(--border-color)
}
body.true span {
    background-color: var(--header-bg); /* Set the background color to white */
    color: var(--main-text); /* Set the text color to the main text color */
    padding: 1px;
    border-radius: 5px;
}
.question-top.true {
    background-color: #fff; /* Set the background color to white */
    color: var(--main-text); /* Set the text color to the main text color */
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    border-color: var(--border-color)
}
.question-top.false {
    background-color: #fff; /* Set the background color to white */
    color: var(--main-text); /* Set the text color to the main text color */
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    border-color: var(--border-color)
}
.question-bottom.true {
    background-color: #fff; /* Set the background color to white */
    color: var(--main-text); /* Set the text color to the main text color */
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    border-color: var(--border-color)
}
.question-bottom.false {
    background-color: #fff; /* Set the background color to white */
    color: var(--main-text); /* Set the text color to the main text color */
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    border-color: var(--border-color)
}
body.true .arrow {
    filter: invert(100);
}
body.true .newsletter-submit {
    background-color:rgb(226, 226, 226) !important;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #000;
}
body.true .newsletter-input {
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #000;
    background-color:rgb(226, 226, 226) !important;
}