body.visible {
    position: fixed;
}

#header {
    position: fixed;
    top: 0;
    z-index: 999;
    height: 75px;
    background-color: var(--header-bg);
    border-style: solid;
    border-width: 1px;
    border-color: var(--border-color);
}

#nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 25px;
}

.hamburger-container {
    display: none;
}

.nav-link {
    color: var(--main-text);
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
}

.nav-link.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logo-img {
    height: 70px;
    width: auto;
}

.popup-link {
    background: none;
    border: none;
    outline: none;
    position: relative;
}

.popup-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--body-main-bg);
    z-index: 2;
    padding: 7px;
    border-radius: 8px;
    width: max-content;
    gap: 7px;
    margin-top: 10px;

    .link {
        color: var(--main-text);
        text-decoration: none;
    }
}

.popup-content.true {
    display: flex;
    flex-direction: column;
}
.logo {
    font-size: 24px;
    font-weight: 500;
}

.hamburger-btn {
    display: none;
}

@media screen and (max-width: 768px) {
    .hamburger-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hamburger-btn {
        width: 28px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .line {
            width: 100%;
            height: 3px;
            background-color: var(--main-text);
        }
    }

    .mobile-list {
        display: none;
    }

    .mobile-list-item {
        display: none;
    }

    #header.visible {
        height: 100vh;

        #nav {
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 40px;
        }

        .nav-list {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .mobile-list {
            display: flex;
            flex-direction: column;
            gap: 40px;
            justify-content: center;
            align-items: center;
        }

        .mobile-list-item {
            display: block;
        }

        .popup-link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .popup-content.true {
            position: relative;
            background: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
    }
}

.dark-mode-switch {
    background: none;
    color: var(--main-text);
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}