.OEE {
  margin-top: 75px;
  height: calc(100vh - 75px);
  position: relative;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.75) 40%,
    rgba(0, 0, 0, 0.5) 60%
  ),
  rgba(0, 0, 0, 0) 80%;
}

.hero-image {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.About-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.text-container {
  text-align: center;
}

.hero-head1 {
  font-size: 80px;
}

.About-text {
  font-size: 28px;
  color: #fff;
  margin-top: 50px;
}


.our-story {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.div-head{
  font-size: 36px;
}

.our-story p {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 15px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.story-image {
  width: auto;
  margin-top: 20px;
  filter: brightness(90%);
}

@media screen and (max-width: 768px) {
  .hero-head1 {
      font-size: 50px;
  }
  .About-text {
      font-size: 20px;
      margin: 20px auto;
  }
  .our-story p {
      text-align: left;
      margin-top: 30px;
      width: 100%;
  }
  .story-image {
      width: 100%;
      height: auto;
      margin-top: 20px;
      filter: brightness(90%);
  }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .hero-head1 {
      font-size: 50px;
  }
  .About-text {
      font-size: 20px;
      margin: 20px auto;
  }
  .our-story p {
      text-align: left;
      margin-top: 30px;
      width: 100%;
  }
  .story-image {
      width: 70vw;
      height: auto;
      margin-top: 20px;
      filter: brightness(90%);
  }
}

@media screen and (min-width: 901px) {
  .story-image {
      width: 50vw;
  }
}

