.slide-container .wrapper .content {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.slide-container {
    margin-top: 75px;
    height: calc(100vh - 75px);
    position: relative;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.75) 40%,
      rgba(0, 0, 0, 0.5) 60%
    ),
    rgba(0, 0, 0, 0) 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  
    .nappi{
      background: none;
    }
  }
  
  .hero-image {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .slider-buttons {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .slider-right,
  .slider-left {
    width: 50px; 
    height: 50px; 
    background-color: transparent; 
    border: none; 
    cursor: pointer; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-right {
    margin-right: auto; 
    transform: rotate(90deg);
  }
  
  .slider-left {
    transform: rotate(270deg);
  }
  
  
  .slider-right img,
  .slider-left img {
    width: 100%; 
    height: auto; 
    background: none;
    display: block;
  }
  
  
  .send {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto multiple lines */
    justify-content: center; /* Centers the items horizontally */
    height: auto;
    width: 100%;
    align-items: center;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
    }
  }
  
  .send-image {
    width: 30%;
    margin: 30px 0;
    max-width: 100%;
    border-radius: 8px;
  }
  
  @media screen and (max-width: 768px) {
    .send-image {
      width: 100%;
    }
  }
  
  .reviews {
    max-width: 100%;
    height: auto;
  }

.review-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.review-arrow-btn {
  background: none;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

#left-arrow {
  transform: rotate(90deg);
}

#right-arrow {
  transform: rotate(-90deg);
}

body.true .review-arrow-btn {
  filter: invert(100);
}

body.false .review-arrow-btn {
  filter: invert(100);
}

.review-container {
  background-color: var(--header-bg);
  padding: 20px;
  width: 75%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.review-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.r-t-left {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}

.r-t-l-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 100px;
}

.user-name {
  font-size: 22px;
  letter-spacing: 0.75px;
  font-weight: 500;
  color: var(--main-text);
}

.time-played {
  font-size: 16px;
  color: var(--second-text);
  font-weight: 350;
  letter-spacing: 0.6px;
}

.user-avatar {
  width: 120px;
  height: 120px;
  border-radius: 8px;
}

.steam-profile {
  color: var(--second-text);
  font-weight: 300;
  letter-spacing: 0.5px;
}

.r-t-right {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.recommended {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 400;
}

.thumb-up {
  height: 120px;
  border-radius: 8px;
}

.review-bottom {
  border-top: 1px solid var(--third-text);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-text {
  margin-top: 32px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.9px;
  font-weight: 400;
}

.requirement-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 20px;

  div {
    width: 50%;
  }

  ul {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

@media screen and (max-width: 920px) {
  .requirement-grid {
    flex-direction: column;

    div {
      width: 100%;
    }
  }
}

.Language ul {
  background-color: #424242;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
}

.Language ul li a {
  color: black;
  padding: 5px 1px;
  text-decoration: none;
  display: block;
  text-align: center
}

.Language ul li a:hover {
  background-color: #ddd;
}

@media screen and (max-width: 850px) {
  .recommended {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .thumb-up {
    width: 64px;
    height: 64px;
  }

  .user-avatar {
    width: 96px;
    height: 96px;
  }

  .r-t-left {
    gap: 12px;
  }

  .r-t-l-right {
    height: 90px;
  }

  .review-text {
    font-size: 16px;
  }

  #right-arrow, #left-arrow {
    width: 24px;
    height: 24px;
  }

  .review-container {
    gap: 24px;
  }

  .review-text {
    margin-top: 24px;
  }
}

@media screen and (max-width: 540px) {
  .user-avatar {
    width: 72px;
    height: 72px;
  }

  .r-t-l-right {
    height: 72px;
  }

  .user-name {
    font-size: 18px;
  }

  .time-played {
    font-size: 14px;
  }

  .steam-profile {
    font-size: 14px;
  }
  
  #right-arrow, #left-arrow {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 480px) {
  .thumb-up {
    height: 48px;
    width: 48px;
  }
}

@media screen and (max-width: 450px) {
  .user-avatar {
    width: 55px;
    height: 55px;
  }
}

@media screen and (max-width: 426px) {
  .review-top {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 12px;
  }

  .r-t-right {
    flex-direction: row-reverse;
    gap: 12px;
  }

  .recommended {
    display: block;
    font-size: 16px;
  }
}