.heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.heading.true {
    text-align: center;
}

.div-head {
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.75px;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.75px;
    }
}

.div-head-text {
    width: 600px;
    margin: 0 auto;
    font-size: 20px;
    letter-spacing: 0.75px;
    font-weight: 350;
    color: var(--third-text);
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .div-head-text {
        width: 100%;
        font-size: 16px;
    }
}

.div-heading-line {
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, var(--body-main-bg) 0%, var(--main-text) 5%, var(--main-text) 95%, var(--body-main-bg) 100%);
}

@media screen and (max-width: 600px) {
  .div-head {
    font-size: 22px !important;
  }
}