#news-landing {
    width: 100%;
    height: 480px;
    position: relative;
    margin-top: 20px;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 30px;
    }
}

.landing-background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
}

.l-hiring-head {
    font-weight: 400;
    letter-spacing: 0.76px;
    font-size: 36px;
    color: #fff;
}

@media screen and (max-width: 1200px) {
    .l-hiring-head {
        font-size: 32px;
    }
}

@media screen and (max-width: 980px) {
    .l-hiring-head {
        font-size: 28px;
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px) {
    .l-hiring-head {
        font-size: 24px;
        width: 95%;
    }
}