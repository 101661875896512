@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
}

body {
    width: 100vw;
    overflow-x: hidden;
    position: relative;
    background-color: var(--body-main-bg);
    color: var(--main-text);
    font-family: Poppins, sans-serif;
}

.wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        width: 100%;
        max-width: 1300px;
    }
}

.btn-blue {
    background-color: var(--blue);
    color: var(--main-text);
    text-decoration: none;
    cursor: pointer;
    padding: 8px 36px;
    border-radius: 30px;
    font-size: 22px;
    transition: 0.2s ease;
    width: max-content;

    &:hover {
        transform: translateY(-3px);
        box-shadow: 3px 3px 8px rgba(0,0,0,0.6);
    }
}

.small {
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 300;
    padding: 4px 28px;
}

@media screen and (max-width: 1600px) {
    .wrapper .content {
        max-width: 88vw;
    }
}

@media screen and (max-width: 1180px) {
    .wrapper .content {
        max-width: 95vw;
    }
}

@media screen and (max-width: 768px) {
    .wrapper .content {
        max-width: 90vw;
    }
}

@media screen and (max-width: 600px) {
    .wrapper .content {
        max-width: 85vw;
    }

    .btn-blue {
        font-size: 16px;
    }
}

.clean-link {
    color: var(--third-text);
    text-decoration: underline;
}

.clean-link.low-weight {
    font-weight: 300;
}
