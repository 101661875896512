@keyframes popup-opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.cookie-popup-wrapper {
    opacity: 0;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 400px;
    height: max-content;
    background-color: var(--body-main-bg);
    z-index: 999;
    border-radius: 8px;
    color: var(--main-text);
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: popup-opacity 0.5s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1.0s;
}

.cookie-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-cookie {
    width: 34px;
    height: 34px;
    background: none;
    color: var(--main-text);
    cursor: pointer;
    font-size: 30px;
    display: flex;
    justify-content: end;
    align-items: center;

    &:hover {
        opacity: 0.75;
    }
}

.cookie-popup-wrapper.hidden {
    display: none;
}

.cookie-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cookie-head {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
}

.cookie-text {
    font-size: 17px;
    margin-top: 15px;
}

.cookie-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 15px;
}

.cookie-btn {
    padding: 12px 36px;
    border-radius: 8px;
    outline: none;
    border: 1px solid var(--main-text);
    cursor: pointer;
    background: none;
    color: var(--main-text);
    font-size: 14px;
    font-weight: 750;
    letter-spacing: 0.75px;

    &:hover {
        opacity: 0.75;
    }
}

.cookie-btn.filled {
    background-color: var(--main-text);
    color: var(--body-main-bg);
}

@media screen and (max-width: 810px) {
    .cookie-popup-wrapper {
        width: calc(100vw - 90px);
        right: 15px;
        bottom: 15px;
        height: max-content;
    }

    .cookie-text {
        margin-top: 15px;
    }

    .cookie-buttons {
        margin-top: 20px;
    }
}

@media screen and (max-width: 600px) {
    .cookie-buttons {
        flex-direction: column;
    }
    
    .cookie-btn {
        width: 100%;
    }
}