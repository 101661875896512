.game {
    width: 400px;
    height: 606px;
    display: flex;
    flex-direction: column;
    background-color: var(--header-bg);
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--border-color)
}

.game-image {
    width: 100%;
    height: 382px;
    border-radius: 8px 8px 25px 25px;
    object-fit: cover;
    image-rendering: pixelated;
}

.game-bottom {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;

    a {
        margin-top: 15px;
        width: max-content;
    }
}

.game-name {
    font-size: 24px;
}

.description {
    font-size: 18px;
    font-weight: 300;
    color: var(--third-text);
}

@media screen and (max-width: 1900px) {
    .game {
        width: 420px;
        position: relative;

        a {
            position: absolute;
            bottom: 15px;
        }
    }
}

@media screen and (max-width: 1650px) {
    .game {
        width: 400px;
    }
}

@media screen and (max-width: 1580px) {
    .game {
        width: 430px;
    }
}

@media screen and (max-width: 1500px) {
    .game {
        width: 410px;
    }
}

@media screen and (max-width: 1430px) {
    .game {
        width: 390px;
        height: 450px;
    }

    .game-image {
        height: auto;
        aspect-ratio: 2 / 1;
    }
}

@media screen and (max-width: 1370px) {
    .game {
        width: 370px;
    }
}

@media screen and (max-width: 1300px) {
    .game {
        width: 350px;
    }
}

@media screen and (max-width: 1220px) {
    .game {
        width: 340px;
    }
}

@media screen and (max-width: 1180px) {
    .game {
        width: calc(33% - 15px);
    }
}

@media screen and (max-width: 968px) {
    .game {
        width: 100%;
        height: max-content;

        a {
            position: relative;
        }
    }

    .game-name {
        font-size: 28px;
    }

    .description {
        font-size: 22px;
    }
}

@media screen and (max-width: 600px) {
    .game-name {
        font-size: 22px;
    }

    .description {
        font-size: 16px;
    }
}