#landing {
    margin-top: 75px;
    height: calc(100vh - 75px);
    position: relative;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.75) 40%, rgba(0,0,0,0.5) 60%), rgba(0,0,0,0) 80%;

    .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 50px;
        position: absolute;
        z-index: 1;
    }
}

.hero-image {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    image-rendering: pixelated;
}

.hero-head {
    font-size: 64px;
    font-weight: 600;
    letter-spacing: 0.9px;
    color: #fff;
}

.hero-text {
    font-size: 28px;
    letter-spacing: 0.75px;
    width: 800px;
    color: #fff !important;
}

@media screen and (max-width: 820px) {
    .hero-text {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    #landing {
        height: calc(80vh - 75px);
    }

    #landing .content {
        gap: 25px;

        .btn-blue {
            font-size: 3vw;
        }
    }

    .hero-head {
        font-size: 8.5vw;
    }

    .hero-text {
        font-size: 3.25vw;
        width: 100%;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 400px) {
    #landing {
        height: calc(70vh - 75px);
    }

    #landing .content {
        gap: 25px;

        .btn-blue {
            font-size: 4vw;
        }
    }

    .hero-head {
        font-size: 11vw;
    }

    .hero-text {
        font-size: 4.5vw;
        width: 100%;
        margin-bottom: 10px;
    }
}