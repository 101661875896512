.update-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    scroll-snap-type: x mandatory;
    gap: 24px;
    grid-auto-flow: column;
    grid-auto-columns: repeat(3,1fr);
    overflow-x: auto;
    padding-bottom: 24px;
}

.update-container {
    width: 420px;
    display: flex;
    flex-direction: column;
    height: max-content;
    background-color: var(--header-bg);
    border-radius: 8px;
    text-decoration: none;
    color: var(--main-text);
}

.update-top {
    height: 400px;
    width: 100%;
}

.update-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 12px 12px;
}

.update-content {
    height: max-content;
    min-height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 12px;
}

.u-c-top {
    width: calc(100% - 24px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.update-name {
    font-size: 22px;
    letter-spacing: 0.5px;
    font-weight: 400;
}

.update-date {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.6px;
    padding: 2px 16px;
    width: max-content;
    height: max-content;
    background-color: var(--border-color);
    border-radius: 25px;
}

.u-c-bottom {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.update-description {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.6px;
    color: var(--third-text);
    width: 90%;
}

@media screen and (max-width: 600px) {
    .update-container {
        width: 380px;
    }
}