#update-content {
    margin-top: 75px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.update-hero {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.hero-top {
    width: 100%;
    display: flex;
    justify-content: end;
}

.update-img {
    width: 80%;
    object-fit: cover;
    height: 450px;
    border-radius: 8px;
}

.hero-bottom {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
}

.h-b-full-width {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.update-head {
    font-size: 42px;
    letter-spacing: 0.75px;
    font-weight: 500;
}

.hero-update-date {
    font-size: 18px;
    letter-spacing: 0.75px;
    color: var(--third-text);
}

.update-change-log {
    margin-top: 50px;
    margin-bottom: 20px;
}

.change-log-head {
    font-size: 28px;
    font-weight: 450;
    letter-spacing: 0.5px;
}

.c-l-head {
    font-size: 20px;
}

.change-log-data {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.change-log-list {
    margin-left: 22px;
    color: var(--third-text);
    letter-spacing: 0.5px;
    margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .update-img {
        width: 100%;
    }
}