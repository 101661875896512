.game-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

@media screen and (max-width: 968px) {
    .game-grid {
        flex-direction: column;
        gap: 14px;
    }
}