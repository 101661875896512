#form {
    margin-top: 75px;
    padding-top: 50px;
    padding-bottom: 50px;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

select {
    color: var(--body-main-bg);
}

.contact-form {
    width: 650px;
    height: 670px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--header-bg);
    border-radius: 8px;
    border: 1px solid var(--border-color);
    margin-bottom: 25px;
}

.form-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.center-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form-flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.form-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.form-text-input {
    padding: 12px;
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: var(--main-text);
    resize: none;
    letter-spacing: 0.75px;
    font-family: 'Poppins';
    font-weight: 450;
    color: var(--body-main-bg);

    &::placeholder {
        color: var(--border-color);
        opacity: 0.7;
    }
}

.submit-btn {
    outline: none;
    border: none;

    &:focus {
        border: none;
        outline: none;
    }
}

button {
    outline: none;
    border: none;
}

@media screen and (max-width: 768px) {
    #form {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .contact-form {
        height: auto;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .form-flex {
        flex-direction: column;
    }
}