.footer {
  background-color: var(--header-bg);
  color: var(--main-text);
  padding: 15px 0 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
  .footer-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      flex: 1;
      width: max-content;
    }

    h4 {
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 5px;
        a {
          color: var(--main-text);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    span {
      color: var(--main-text);
    }
  }

  .contact-info {
    flex-basis: 100%; 
    
  }

  .copyright {
    flex-basis: 100%; 
    margin-top: 20px; 
    padding: 10px;
  }

  .Language ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .Language ul.show {
    background-color: var(--body-main-bg);
    height: 75px;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    position: absolute;
  }

  @media screen and (max-width: 786px){
    .Language ul{
    background-color: var(--body-main-bg);
  }
}
 
  
  .Language ul.show li a {
    color: var(--main-text);
    text-decoration: none;
  }
.esimerkki-class {
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: max-content;
}

.mobile-button {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-button {
    display: flex;
    color: var(--second-text);
  }

  .footer-section {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  ul.show {
    display: none;
  }
}

.popup-toggler {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.popup-toggler img {
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.3s ease all;
  margin-left: 15px;
}

.popup-toggler.false img {
  transform: rotate(180deg);
}

@media screen and (max-width: 769px) {
  .bottom.show {
    display: none;
  }  
}

.newsletter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0;
  .top {
    display: flex;
    flex-direction: row;
  }
}


.newsletter-container {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: max-content;
  padding: 8px;
  background-color: var(--body-main-bg);
  border-radius: 8px;
  margin-right: 24px;
  margin-bottom: 12px;
}

.newsletter-input {
  background: none;
  outline: none;
  border: none;
  color: var(--main-text);
  font-size: 14px;
  letter-spacing: 0.75px;
  font-weight: 300;
  font-family: Poppins;

  &::placeholder {
    font-weight: 300;
    color: var(--third-text);
  }
}

.newsletter-submit {
  padding: 6px 14px;
  width: max-content;
  background-color: var(--border-color);
  color: var(--main-text);
  cursor: pointer;
  border-radius: 8px;
  letter-spacing: 0.9px;
  font-weight: 400;
  font-family: Poppins;

  &:hover {
    opacity: 0.7;
  }
}

@media screen and (min-width: 769px) {
  .popup-toggler img {
    display: none; /* Hide for screens wider than 768px */
  }
}
}

.language-btn {
  outline: none;
  border: none;
  background-color: var(--body-main-bg);
  width: 100%;
  height: 37px;
  display: flex;
  border-radius: 8px 8px 0 0;
  justify-content: center;
  align-items: center;
  color: var(--main-text);
  cursor: pointer;
  &:hover {
    background-color: var(--header-bg);
  }
}

#l2 {
  border-radius: 0 0 8px 8px;
}

@media screen and (max-width: 1140px) {
  .footer .footer-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
  }
}

@media screen and (max-width: 850px) {
  .footer .footer-section {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-section {
    display: flex;
  }
}