.policy-wrapper {
    margin-top: 75px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.white {
    color: var(--main-text) !important;
}

.policy-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.policy-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid #707070;
    padding-bottom: 20px;
}

.policy-item:last-child {
    border: none;
}

.policy-head-main {
    font-size: 36px;
    font-weight: 350;
    letter-spacing: 0.8px;
    color: var(--main-text);
}

.policy-head {
    font-size: 24px;
    letter-spacing: 0.75px;
    font-weight: 400;
}

.policy-text {
    font-size: 18px;
    letter-spacing: 0.7px;
    font-weight: 300;
    color: var(--third-text);
}

.policy-list {
    margin-left: 24px;
}

@media screen and (max-width: 1120px) {
    .policy-item {
        gap: 15px;
        padding-bottom: 15px;
    }

    .policy-head-main {
        font-size: 32px;
    }

    .policy-head {
        font-size: 22px;
    }

    .policy-text {
        font-size: 16px;
    }
}