.hero-image {
    transition: 1s;
}

.hero-image:last-child {
    opacity: 0;
}

.hero-image.visible {
    opacity: 0;
}