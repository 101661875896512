#not-found {
    height: 100vh;

    .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.not-found-head {
    font-size: 84px;
    font-weight: 450;
    letter-spacing: 1px;
    margin-bottom: 10px;
    color: var(--main-text);
}

.not-head-small {
    font-size: 38px;
    font-weight: 350;
    color: var(--secondary-text);
    margin-bottom: 50px;
}

.not-head-long {
    font-size: 22px;
    width: 680px;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.75px;
    color: var(--secondary-text);
    margin-bottom: 70px;
}

@media screen and (max-width: 820px) {
    .not-found-head {
        font-size: 72px;
    }

    .not-head-small {
        font-size: 32px;
    }

    .not-head-long {
        width: 100%;
        max-width: 700px;
    }
}

@media screen and (max-width: 500px) {
    .not-found-head {
        font-size: 64px;
    }

    .not-head-small {
        font-size: 28px;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    .not-head-long {
        font-size: 18px;
        width: 100%;
        margin-bottom: 50px;
    }
}