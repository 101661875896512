#faq {
    margin-top: 75px;
    margin-bottom: 20px;
    min-height: 70vh;
}

.faq-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.faq-side {
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.faq-side:last-child {
    align-items: flex-end;
    padding-right: 50px;
}

.question-grid {
    gap: 15px;
}

.question-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.question-top {
    width: calc(100% - 48px);
    background-color: var(--main-text);
    padding: 8px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.q-text {
    color: var(--header-bg);
    font-size: 18px;
}

.q-top-right {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: end;

    img {
        height: 18px;
        width: 18px;
        transition: 0.3s ease all;
    }
}

.question-wrapper.true {
    .q-top-right img {
        transform: rotate(180deg);
    }
}

.question-wrapper.false {
    .question-bottom {
        display: none;
    }
}

.faq-image {
    width: 60%;
    height: auto;
}
.arrow-black.true {
    filter: invert(100%);
}

@media screen and (max-width: 860px) {
    .faq-side {
        width: 60%;
    }

    .faq-side:last-child {
        width: 35%;
    }
}

@media screen and (max-width: 750px) {
    .faq-side {
        width: 100%;
    }

    .faq-side:last-child {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .faq-side {
        width: 100%;
    }

    .question-top {
        padding: 8px 16px;
        width: calc(100% - 32px);
    }

    .q-text {
        font-size: 16px;
    }

    .q-top-right img {
        width: 16px;
        height: 16px;
    }

    .q-answer {
        font-size: 16px;
    }
}