.role-grid {
    padding: 40px 0 40px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.role-group {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    width: 100%;
}

.role-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.role-left {
    width: 33%;
}

.role-head {
    font-size: 32px;
    font-weight: 450;
    letter-spacing: 0.75px
}

.role-right {
    width: 66%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.role-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .top-left {
        width: max-content;
    }

    .top-right {
        width: max-content;
    }
}

.role-name {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.8px;
}

.role-toggler {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
        height: 100%;
        transition: 0.3s ease all;
    }
}

.role-bottom {
    display: none;
}

.role-bottom-line {
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, var(--body-main-bg) 0%, var(--main-text) 5%, var(--main-text) 95%, var(--body-main-bg) 100%);
}

.role-item.true {
    .role-bottom {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .role-toggler img {
        transform: rotate(180deg);
    }
}

.role-text {
    color: var(--third-text);
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 300;
}


@media screen and (max-width: 900px) {
    .role-grid {
        gap: 10px;
    }

    .role-group {
        flex-direction: column;
        gap: 10px;
    }

    .role-head {
        font-size: 32px;
    }

    .role-left, .role-right {
        width: 100%;
    }

    .role-item {
        gap: 10px;
    }

    .role-name {
        font-size: 18px;
    }

    .role-toggler {
        width: 15px;
        height: 15px;
    }

    .role-text {
        font-size: 14px;
    }

    .btn-blue.small {
        padding: 2px 22px;
    }
}