#hero {
    margin-top: 75px;
    height: 65vh;
    position: relative;

    .content {
        display: flex;
        flex-direction: column;
        gap: 27px;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}

.hero-head {
    font-size: 68px;
    font-weight: 400;
    letter-spacing: 1px;
}

.hero-text {
    color: var(--third-text);
    font-weight: 350;
    letter-spacing: 0.75px;
    font-size: 28px;
}

@media screen and (max-width: 600px) {
    .hero-head {
        font-size: 36px;
    }

    .hero-text {
        font-size: 18px;
        word-break: break-word;
    }
}